<template>
  <div class="d-flex flex-column">
    <v-row>
      <v-col cols="12" sm="10" md="9" lg="8" xl="7">
        <v-card class="pa-8 mb-4" color="surface">
          <v-list class="transparent pa-0">
            <v-list-item v-for="(item, i) in details" class="px-0" :key="i">
              <div class="d-flex flex-column flex-grow-1 justify-center">
                <div
                  class="d-flex justify-space-between flex-grow-1 align-center px-1"
                >
                  <span
                    class="text--body-3 font-weight-bold"
                    v-text="item.label"
                  />
                  <span class="text--body-3" v-text="item.value" />
                </div>
                <v-divider />
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  props: {
    league: {
      type: null,
      required: true,
    },
  },

  computed: {
    _isSolo: function () {
      return this.league.x < 2;
    },

    details() {
      return [
        {
          label: "Status",
          value: this.league.isActive ? "Ativa" : "Inativa",
        },
        {
          label: "Modo",
          value: this._isSolo ? "Solo" : `Equipe (x${this.league.x})`,
        },
        {
          label: "Inscrições",
          value: `${formatDate(
            this.league.enrollmentsInitDate,
            "DD/MM/YYYY"
          )} ~ ${formatDate(this.league.enrollmentsFinalDate, "DD/MM/YYYY")}`,
        },
        {
          label: "Início dos jogos",
          value: formatDate(this.league.tournamentInitDate, "DD/MM/YYYY"),
        },
        {
          label: "Grande Final",
          value: formatDate(this.league.tournamentFinalDate, "DD/MM/YYYY"),
        },
        {
          label: "Equipes Inscritas",
          value: this.league.teams.length,
        },
      ];
    },
  },

  methods: {
    formatDate,
  },
};
</script>

<style></style>
